import {IGalleryStyleParams, IPropsInjectedByViewerScript, IGallerySantaProps} from '../types/galleryTypes';
import {IHostProps} from '@wix/native-components-infra/dist/es/src/types/types';

type ProvidedGlobalPropNames =
  | 'addedToCartStatus'
  | 'clearFilters'
  | 'currentPage'
  | 'experiments'
  | 'filterModels'
  | 'filterProducts'
  | 'filterProductsOnMobile'
  | 'focusedProductIndex'
  | 'getCategoryProducts'
  | 'handleAddToCart'
  | 'handleMobileSortClick'
  | 'handlePagination'
  | 'handleProductItemClick'
  | 'hasMoreProductsToLoad'
  | 'hasSelectedFilters'
  | 'isAutoGrid'
  | 'isFirstPage'
  | 'isInteractive'
  | 'isLiveSiteMode'
  | 'isPreviewMode'
  | 'isMobile'
  | 'isRTL'
  | 'loadMoreProducts'
  | 'loadMoreType'
  | 'loading'
  | 'mainCollectionId'
  | 'numberOfSelectedFilterTypes'
  | 'openQuickView'
  | 'paginationMode'
  | 'productIdToProductPageUrlMap'
  | 'products'
  | 'quantitySRKeys'
  | 'selectedSort'
  | 'setProductsPerPage'
  | 'shouldShowAddToCartSuccessAnimation'
  | 'shouldShowClearFilters'
  | 'shouldShowMobileFiltersModal'
  | 'shouldShowSort'
  | 'showShowLightEmptyState'
  | 'sortProducts'
  | 'sortingOptions'
  | 'textsMap'
  | 'toggleFiltersModalVisibility'
  | 'totalProducts'
  | 'updateAddToCartStatus';

export interface IGalleryGlobalProps {
  globals?: Pick<IPropsInjectedByViewerScript & IGallerySantaProps, ProvidedGlobalPropNames> & {
    styleParams: IGalleryStyleParams;
  } & Pick<IHostProps, 'updateLayout' | 'dimensions' | 'appLoadBI'> & {compId: string};
}

export const galleryGlobalsStrategy = (props: IPropsInjectedByViewerScript & IGallerySantaProps) => {
  const {
    addedToCartStatus,
    clearFilters,
    currentPage,
    experiments,
    filterModels,
    filterProducts,
    filterProductsOnMobile,
    focusedProductIndex,
    getCategoryProducts,
    handleAddToCart,
    handleMobileSortClick,
    handlePagination,
    handleProductItemClick,
    hasMoreProductsToLoad,
    hasSelectedFilters,
    isAutoGrid,
    isFirstPage,
    isInteractive,
    isLiveSiteMode,
    isPreviewMode,
    isMobile,
    isRTL,
    loadMoreProducts,
    loadMoreType,
    loading,
    mainCollectionId,
    numberOfSelectedFilterTypes,
    openQuickView,
    paginationMode,
    productIdToProductPageUrlMap,
    products,
    quantitySRKeys,
    selectedSort,
    setProductsPerPage,
    shouldShowAddToCartSuccessAnimation,
    shouldShowClearFilters,
    shouldShowMobileFiltersModal,
    shouldShowSort,
    showShowLightEmptyState,
    sortProducts,
    sortingOptions,
    style,
    textsMap,
    toggleFiltersModalVisibility,
    totalProducts,
    updateAddToCartStatus,
    ...locals
  } = props;

  const {appLoadBI, dimensions, updateLayout, id: compId} = props.host;
  return {
    globals: {
      addedToCartStatus,
      appLoadBI,
      clearFilters,
      currentPage,
      compId,
      dimensions,
      experiments,
      filterModels,
      filterProducts,
      filterProductsOnMobile,
      focusedProductIndex,
      getCategoryProducts,
      handleAddToCart,
      handleMobileSortClick,
      handlePagination,
      handleProductItemClick,
      hasMoreProductsToLoad,
      hasSelectedFilters,
      isAutoGrid,
      isFirstPage,
      isInteractive,
      isLiveSiteMode,
      isPreviewMode,
      isMobile,
      isRTL,
      loadMoreProducts,
      loadMoreType,
      loading,
      mainCollectionId,
      numberOfSelectedFilterTypes,
      openQuickView,
      paginationMode,
      productIdToProductPageUrlMap,
      products,
      quantitySRKeys,
      selectedSort,
      setProductsPerPage,
      shouldShowAddToCartSuccessAnimation,
      shouldShowClearFilters,
      shouldShowMobileFiltersModal,
      shouldShowSort,
      showShowLightEmptyState,
      sortProducts,
      sortingOptions,
      styleParams: style.styleParams,
      textsMap,
      toggleFiltersModalVisibility,
      totalProducts,
      updateLayout,
      updateAddToCartStatus,
    },
    locals,
  };
};
